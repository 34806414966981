import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { TLang } from '../../../constants/supported-languages';

@Component({
  selector: 'nv-language-switcher',
  templateUrl: './nv-language-switcher.component.html',
  styleUrls: ['./nv-language-switcher.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NvLanguageSwitcherComponent implements OnInit {
  public selectedLangName: string;
  @Input({ required: true }) selectedLang: TLang;
  @Input({ required: true }) languages: { key: TLang; human: string }[];
  @Input() isDisabled: boolean = false;
  @Input() mixpanelTrackingFn?: (key: TLang) => void;
  @Input() ariaLabelData?: {
    iconAriaLabel?: string | null;
    prependIconAriaLabel?: string | null;
  } = {
      iconAriaLabel: null,
      prependIconAriaLabel: null,
    };

  @Output() switchLanguage = new EventEmitter<string>();

  ngOnInit () {
    this.selectedLangName = this.getLanguageNameFromKey(this.languages, this.selectedLang);
  }

  onSwitchLanguage (dropDownOption: { key: TLang; human: string }) {
    if (this.selectedLangName !== dropDownOption.human) {
      const lang = dropDownOption.key;
      this.selectedLangName = dropDownOption.human;
      if (this.mixpanelTrackingFn) this.mixpanelTrackingFn(lang);
      this.switchLanguage.emit(lang);
    };
  }

  private getLanguageNameFromKey (languages: { key: TLang; human: string }[], key: TLang): string {
    const found = languages?.find((lang) => lang.key === key);
    return found ? found.human : 'English';
  }
}
