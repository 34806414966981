/* eslint-disable no-unused-vars */

export enum FAMILY_EVENT_TYPES {
  VIEWED = 'Viewed',
  MESSAGE_SENT = 'Message Sent',
  MESSAGE_RECEIVED = 'Message Received',
  LANGUAGE_SELECT = 'Language Selected',
}

export enum FAMILY_EVENT_NAMES {
  VIEWED_PAGE = 'Viewed Page',
  PAGE_VIEW_TIME = 'Page View Time',
  MESSAGE = 'Message',
  LANGUAGE_SELECT_ACTION = 'Selected Language Action',
}

export type TFamilyEventName = typeof FAMILY_EVENT_NAMES[keyof typeof FAMILY_EVENT_NAMES];
export type TFamilyEventType = typeof FAMILY_EVENT_TYPES[keyof typeof FAMILY_EVENT_TYPES];
