import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { WindowRef } from '../../../../../shared/services/window-ref/windowRef';

@Component({
  selector: 'family-footer',
  templateUrl: './family-footer.component.html',
  styleUrls: ['./family-footer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FamilyFooterComponent implements OnInit {
  public emergencyText = 'If you are facing a life-threatening emergency and need help right now, call or text 911';
  public links = [
    {
      label: 'About New Visions',
      url: 'https://newvisions.org/pages/about-us',
      target: '_self',
      showLogo: true,
      altText: 'NVPS Logo: “New Visions for Public Schools”',
    },
    {
      label: 'Privacy and Terms',
      url: 'https://familyportal.nyc/privacy-policy',
      target: '_blank',
      showLogo: false,
    },
  ];

  @Input() logoType = 'symbol';

  constructor (
    private windowRef: WindowRef,
  ) {}

  ngOnInit (): void {}

  public onLogoClick (): void {
    const window = this.windowRef.nativeWindow;
    window.open('https://newvisions.org/pages/about-us', '_self');
  }
}
