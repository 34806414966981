import { Injectable } from '@angular/core';
import StyleVariables from '../../nvps-libraries/design/styles/variables/variables.json';

@Injectable({ providedIn: 'root' })
export class ResponsiveLayoutService {
  protected smallBreakpoint = StyleVariables.MediaQueryVariables['screen-size-breakpoint-sm'];
  protected mediumBreakpoint = StyleVariables.MediaQueryVariables['screen-size-breakpoint-md'];
  protected smallBreakpointNumber = parseInt(this.smallBreakpoint.replace('px', ''), 10);
  protected mediumBreakpointNumber = parseInt(this.mediumBreakpoint.replace('px', ''), 10);

  constructor () { }

  public isSmallViewportWidth ({ innerWidth, offsetWidth }: any): boolean {
    const width = innerWidth || offsetWidth || 0;
    return width < this.smallBreakpointNumber;
  }

  public isMediumViewportWidth ({ innerWidth, offsetWidth }: any): boolean {
    const width = innerWidth || offsetWidth || 0;
    return width < this.mediumBreakpointNumber;
  }
}
