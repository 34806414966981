import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import { DistrictIdInterceptor } from './district-id.interceptor';
import { NvPusherSocketId } from './nv-pusher-socket-interceptor';

@NgModule()
export class HttpInterceptorModule {
  static forRoot () {
    return {
      ngModule: HttpInterceptorModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthHttpInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: DistrictIdInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: NvPusherSocketId,
          multi: true,
        },
      ],
    };
  }
}
