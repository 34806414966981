import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { NvIconModule } from '../../../../shared/nvps-libraries/design/nv-icon/nv-icon.module';
import { SidebarItemComponent } from './sidebar-item.component';
import { RouterModule } from '@angular/router';
import { NvAriaLabelModule } from '../../../../shared/nvps-libraries/design/directives/nv-aria-label/nv-aria-label.module';
import { A11yModule } from '@angular/cdk/a11y';

@NgModule({
  imports: [CommonModule, NvIconModule, MatRippleModule, RouterModule, NvAriaLabelModule, A11yModule],
  declarations: [SidebarItemComponent],
  exports: [SidebarItemComponent],
})
export class SidebarItemModule {}
