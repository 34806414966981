import { FlatTreeControl } from '@angular/cdk/tree';
import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, Output, QueryList, SimpleChanges, ViewChildren, ViewEncapsulation } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { ISidebarFlatNode, ISidebarItem } from './sidebar.interface';
import { FocusKeyManager } from '@angular/cdk/a11y';
import { SidebarItemComponent } from '../sidebar-item/sidebar-item.component';

/**
 *
 * Displays a list of options. Typically used for routing/navigation or selecting from a set of actions.
 *
 * Omit the `title` when the context of the sidebar makes a title redundant (e.g. Settings).
 *
 */
@Component({
  selector: 'sidebar-list',
  templateUrl: './sidebar-list.component.html',
  styleUrls: ['./sidebar-list.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarListComponent {
  /**
   *
   *
   * Tree of `ISidebarItems`
   *
   * interface ISidebarItem {
   *   human: string;
   *   key: string;
   *   leftDefaultIcon?: string;
   *   leftSelectedIcon?: string;
   *   rightDefaultIcon?: string;
   *   rightSelectedIcon?: string;
   *   expandAs?: 'accordion' | 'dropdown';
   *   children?: ISidebarItem[] | IDropdownOption[];
   *   disabled?: boolean;
   *   url?: string;
   *   queryParams?: {
   *     filter?: string;
   *     focus?: string;
   *     sort?: string;
   *     grouping?: string;
   *     search?: string;
   *   };
   *   hasBetaFlag?: boolean;
   *   lastChild?: boolean;
   * }
   * ```
   *
   * `expandAs` defines whether the children appear as a dropdown menu, or in an accordion submenu.
   * When set to `accordion`, any `icon` property will be ignored.
   *
   * Note: Nested accordions are not yet supported.
   *
   */
  @Input() listData: ISidebarItem[];

  /**
   *
   * The `key` of the selected option
   */
  @Input() selectedKey: string; // the selected key

  /**
   *
   * The `keys` of the listData that should be expanded on init
   */
  @Input() expandedNodes: string[];

  /**
   *
   * The title of the list
   */
  @Input() title: string;

  /**
   *
   * The subtitle of the list
   */
  @Input() subtitle: string;

  /**
   *
   * Determines whether the subtitle pulses on change. This is particularly useful for batch actions when the subtitle changes based on the selection.
   */
  @Input() shouldAnimateSubtitle: boolean = false;

  /**
  *
  * Determines if used as a right side nav content tools
  */
  @Input() toolsMode: boolean = false;

  /**
   *
   * Emits the `key` of the selected item (or child item)
   */
  @Output() sidebarListItemSelect: EventEmitter<string> = new EventEmitter<string>();

  private _transformer = (node: ISidebarItem, level: number) => {
    return {
      expandable: node.expandAs === 'accordion' && !!node.children && node.children.length > 0,
      human: node.human,
      key: node.key,
      url: node.url,
      level,
      expandAs: node.expandAs,
      children: node.children,
      disabled: !!node.disabled,
      leftDefaultIcon: node.leftDefaultIcon,
      leftSelectedIcon: node.leftSelectedIcon,
      leftDefaultIconAriaLabel: node.leftDefaultIconAriaLabel,
      leftSelectedIconAriaLabel: node.leftSelectedIconAriaLabel,
      queryParams: node.queryParams,
      hasBetaFlag: node.hasBetaFlag,
      lastChild: node.lastChild,
    };
  };

  treeControl = new FlatTreeControl<ISidebarFlatNode>(node => node.level, node => node.expandable);
  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  hasChild = (_: number, node: ISidebarFlatNode) => node.expandable;

  public caretDownDefaultIcon = 'caret-down-default';
  public caretDownSelectedIcon = 'caret-down-selected';
  public caretRightDefaultIcon = 'caret-right-default';
  public caretRightSelectedIcon = 'caret-right-selected';

  @ViewChildren(SidebarItemComponent) sidebarItems: QueryList<SidebarItemComponent>;
  private focusKeyManager: FocusKeyManager<SidebarItemComponent>;

  constructor (public changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit (): void {
    this.dataSource.data = this.listData;
    this.addLastChildProperty();

    // Expands the tree nodes of a selected nested item by default
    for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
      if (this.nodeIsSelected(this.treeControl.dataNodes[i])) {
        this.treeControl.expand(this.treeControl.dataNodes[i]);
      }
    }

    // Expands the tree nodes given by the parent
    for (const node of this.treeControl.dataNodes) {
      if (this.expandedNodes?.includes(node.key)) {
        this.treeControl.expand(node);
      }
    }
  }

  ngOnChanges (changes: SimpleChanges) {
    const { listData, selectedKey } = changes;
    if (listData && listData.currentValue !== this.dataSource.data) this.dataSource.data = listData.currentValue;
    if (selectedKey && !selectedKey.firstChange) {
      // Blurs the previously selected button
      const buttonToBlur = document.getElementById(`${selectedKey.previousValue}-sidebar-item`)?.getElementsByTagName('button')[0];
      buttonToBlur?.blur();
    }
  }

  handleSelect (key: string) {
    this.sidebarListItemSelect.emit(key);
  }

  nodeIsSelected (node: ISidebarFlatNode): boolean {
    return node.key === this.selectedKey || (node.children && node.children.map(n => n.key).includes(this.selectedKey));
  }

  nodeIsLastNode (node: ISidebarFlatNode): boolean {
    const nodeIndex = this.dataSource.data.map(n => n.key).indexOf(node.key);
    return nodeIndex === this.dataSource.data.length - 1;
  }

  /**
   * This method flags the last child of a parent node for styling purposes
   */

  addLastChildProperty () {
    this.dataSource.data = this.dataSource.data.map((node) => {
      const children = node.children;
      if (children && children.length > 0) {
        const lastChildIndex = children.length - 1;
        let lastChildNode = children[lastChildIndex];
        lastChildNode = {
          lastChild: true,
          ...lastChildNode,
        };
        children.pop();
        children.push(lastChildNode);
      }
      return node;
    });
  }

  ngAfterContentInit () {
    // 1. Manually trigger change detection so that this.sidebarItems is populated
    this.changeDetectorRef.detectChanges();
    // 2. Instantiate FocusKeyManager - wrap sidebarItems inside a FocusKeyManager
    this.focusKeyManager = new FocusKeyManager(this.sidebarItems).withWrap();
  }

  @HostListener('keydown', ['$event'])
  onKeydown (event) {
    // trigger the key manager on keydown
    this.focusKeyManager.onKeydown(event);
  }
}
