<ng-container *transloco="let t">
<div class="family-footer">
  <span class="emergency-text">{{ t(emergencyText) }}</span>
  <div class="footer-links">
    <div class="footer-link" *ngFor="let link of links">
      <nv-logo
        *ngIf="link.showLogo"
        (click)="onLogoClick()"
        [pointerOnHover]="true"
        [type]="logoType"
        nvAriaLabel
        [ariaLabelText]="t(link.altText)"
        ariaTargetElement="svg"
        ></nv-logo>
      <a
        class="footer-link"
        [href]="link.url"
        [target]="link.target"
      >{{ t(link.label) }}
    </a>
    </div>
  </div>
</div>
</ng-container>