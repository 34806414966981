import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatTreeModule } from '@angular/material/tree';
import { NvDropdownItemModule } from '../../../../shared/nvps-libraries/design/nv-dropdown-item/nv-dropdown-item.module';
import { SidebarItemModule } from '../sidebar-item/sidebar-item.module';
import { SidebarListResponsiveComponent } from './sidebar-list-responsive.component';
import { SidebarListModule } from '../sidebar-list/sidebar-list.module';
import { A11yModule } from '@angular/cdk/a11y';

@NgModule({
  imports: [
    CommonModule,
    MatTreeModule,
    MatMenuModule,
    NvDropdownItemModule,
    SidebarItemModule,
    SidebarListModule,
    A11yModule,
  ],
  declarations: [SidebarListResponsiveComponent],
  exports: [SidebarListResponsiveComponent],
})
export class SidebarListResponsiveModule {}
