import { TFormatPageViewTimeEventArgs, IPageViewTimeMetadata } from './../../../../../shared/typings/interfaces/page-view-time-action';
import { TViewedPageEventArgs, IViewedPageMetadata } from './../../../../../shared/typings/interfaces/viewed-page-action';
import { ILanguageSelectMetaData, TLanguageSelectEventArgs } from './../../../../../shared/typings/interfaces/language-select-action';
import { Injectable } from '@angular/core';
import { TFamilyMixpanelEvent } from 'src/app/services/mixpanel/family-portal-mixpanel.service';
import { FAMILY_EVENT_NAMES, FAMILY_EVENT_TYPES } from '../../../../../shared/typings/interfaces/family-mixpanel.interface';
import { IMessageMetaData, TMessageEventArgs } from '../../../../../shared/typings/interfaces/message-action';

@Injectable()
export class FamilyEventFormatterService {
  constructor () { }
  public getViewedPageEvent ({ page, portal }: TViewedPageEventArgs): TFamilyMixpanelEvent<IViewedPageMetadata> {
    return {
      event: FAMILY_EVENT_NAMES.VIEWED_PAGE,
      metaData: {
        action: FAMILY_EVENT_TYPES.VIEWED,
        page,
        portal,
      },
    };
  }

  public getPageViewTimeEvent ({ page, sessionTime, portal }: TFormatPageViewTimeEventArgs): TFamilyMixpanelEvent<IPageViewTimeMetadata> {
    return {
      event: FAMILY_EVENT_NAMES.PAGE_VIEW_TIME,
      metaData: {
        action: FAMILY_EVENT_TYPES.VIEWED,
        sessionTime,
        page,
        portal,
      },
    };
  }

  public getMessageEvent ({ portal, action, conversationId, timestamp, tags }: TMessageEventArgs): TFamilyMixpanelEvent<IMessageMetaData> {
    return {
      event: FAMILY_EVENT_NAMES.MESSAGE,
      metaData: {
        action,
        conversationId,
        timestamp,
        tags,
        portal,
      },
    };
  };

  public getLanguageSwitcherEvent ({ action, key, language, portal }: TLanguageSelectEventArgs): TFamilyMixpanelEvent<ILanguageSelectMetaData> {
    return {
      event: FAMILY_EVENT_NAMES.LANGUAGE_SELECT_ACTION,
      metaData: {
        action,
        key,
        language,
        portal,
      },
    };
  };
}
