/* eslint-disable no-unused-vars */
export type TLang = 'en' | 'ar' | 'bn' | 'zh-CN' | 'fr' | 'ht' | 'ko' | 'pl' | 'ru' | 'es' | 'ur';

export const enum LANGUAGES {
  ENGLISH = 'English',
  ARABIC = 'Arabic',
  BENGALI = 'Bengali',
  CHINESE = 'Chinese(Simplified)',
  FRENCH = 'French',
  HAITIAN_CREOLE = 'Haitian Creole',
  KOREAN = 'Korean',
  POLISH = 'Polish',
  RUSSIAN = 'Russian',
  SPANISH = 'Spanish',
  URDU = 'Urdu',
}

export const SUPPORTED_LANGUAGES: { key: TLang; human: string }[] = [
  { key: 'en', human: 'English' },
  { key: 'ar', human: 'عربي' }, // Arabic
  { key: 'bn', human: 'বাংলা' }, // Bengali
  { key: 'zh-CN', human: '中文' }, // Chinese(Simplified)
  { key: 'fr', human: 'Français' }, // French
  { key: 'ht', human: 'Kreyòl' }, // Haitian Creole
  { key: 'ko', human: '한국어' }, // Korean
  { key: 'pl', human: 'Polski' }, // Polish
  { key: 'ru', human: 'Русский' }, // Russian
  { key: 'es', human: 'Español' }, // Spanish
  { key: 'ur', human: 'اُردُو' }, // Urdu
];

export const SUPPORTED_LANGUAGES_TO_ENGLISH_MAP: { [key in TLang]: LANGUAGES } = {
  en: LANGUAGES.ENGLISH,
  ar: LANGUAGES.ARABIC,
  bn: LANGUAGES.BENGALI,
  'zh-CN': LANGUAGES.CHINESE,
  fr: LANGUAGES.FRENCH,
  ht: LANGUAGES.HAITIAN_CREOLE,
  ko: LANGUAGES.KOREAN,
  pl: LANGUAGES.POLISH,
  ru: LANGUAGES.RUSSIAN,
  es: LANGUAGES.SPANISH,
  ur: LANGUAGES.URDU,
};
