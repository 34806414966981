import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NvLanguageSwitcherComponent } from './nv-language-switcher.component';
import { NvIconModule } from '../nv-icon/nv-icon.module';
import { NvPillButtonV4Module } from '../nv-pill-button-v4/nv-pill-button-v4.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [
    NvLanguageSwitcherComponent,
  ],
  imports: [
    CommonModule,
    NvIconModule,
    MatMenuModule,
    MatButtonModule,
    NvPillButtonV4Module,
  ],
  exports: [
    NvLanguageSwitcherComponent,
  ],
})
export class NvLanguageSwitcherModule { }
