import { Injectable, Injector } from '@angular/core';
import { TranslocoLoader } from '@jsverse/transloco';
import { catchError, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiService } from './services/api/api-service/api-service';
import { TranslateService } from './services/translate/translate.service';
import { FamilyPortalConfig } from './services/family-portal-config';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor (
    private apiService: ApiService,
    private injector: Injector,
    private familyPortalConfig: FamilyPortalConfig,
  ) {}

  getTranslation (lang: string) {
    if (lang === 'en') return of({}); // english is the default language, so we don't need to fetch translations for it
    let staticTranslations;
    try {
      staticTranslations = require(`../assets/i18n/${lang}.json`);
    } catch (e) {
      staticTranslations = {};
    }
    return this.apiService.getTranslations(lang).pipe(
      tap(() => {
        const { DEV_MODE } = this.familyPortalConfig.publicConfig;
        if (DEV_MODE) {
          const translateService = this.injector.get(TranslateService);
          const staticKeys = Object.keys(staticTranslations);
          const standardizedStaticKeys = translateService.standardizeKeys(staticKeys);
          sessionStorage.setItem('standardizedStaticKeys', JSON.stringify(standardizedStaticKeys));
        }
      }),
      map((translations) => Object.assign({}, translations, staticTranslations)),
      catchError(() => of(staticTranslations)),
    );
  }
}
