  <div mat-dialog-content class="dialog-content" nvFocusTrap>
    <p class="warning-message">{{ data.warningMessage }}</p>
  </div>
  <mat-dialog-actions class="dialog-actions button-group">
    <nv-button size="small" type="secondary" (clickButton)="logOut()">
      Log out
    </nv-button>
    <nv-button size="small" htmlType="submit" (clickButton)="onNoClick()">
      Stay logged in
    </nv-button>
  </mat-dialog-actions>
