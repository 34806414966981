import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Subscription } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { GoogleAnalyticsTrackerService } from '../../../shared/services/google-analytics/google-analytics-tracker.service';
import { ICurrentUser } from './interfaces/current-user.interface';
import { CurrentUserService } from './services/current-user/current-user.service';
import { FamilyPortalConfig } from './services/family-portal-config';


@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  styleUrls: [],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'family-portal';
  private errorSubscription: Subscription;
  private isAuthenticatedSubscription: Subscription;
  errorMessage: string | null = null;

  constructor (
    public authService: AuthService,
    private googleAnalyticsTrackerService: GoogleAnalyticsTrackerService,
    private currentUserService: CurrentUserService,
    private familyPortalConfig: FamilyPortalConfig,
  ) {
    // Initialize Google Analytics
    if (this.familyPortalConfig.publicConfig.GOOG_ANALTICS4_ID) {
      this.googleAnalyticsTrackerService.handleRouteEvents();
    }
  }

  ngOnInit (): void {
    this.errorSubscription = this.authService.error$.subscribe(
      error => {
        console.error('Authentication error:', error);
      },
    );
    this.isAuthenticatedSubscription = this.authService.isAuthenticated$.pipe(
      filter((isAuthenticated) => isAuthenticated),
      switchMap(() => {
        return this.currentUserService.getCurrentUser$().pipe(
          tap((currentUser: ICurrentUser) => {
            const { guardianId } = currentUser;
            this.googleAnalyticsTrackerService.setUserAnalyticsHash(guardianId);
          }),
        );
      }),
    ).subscribe();
  }

  ngOnDestroy (): void {
    this.errorSubscription.unsubscribe();
    this.isAuthenticatedSubscription.unsubscribe();
  }
}
