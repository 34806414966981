import { Injectable, Injector } from '@angular/core';
import { TranslocoMissingHandler, TranslocoConfig, TranslocoService } from '@jsverse/transloco';
import { tap } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api/api-service/api-service';

@Injectable()
export class CustomHandler implements TranslocoMissingHandler {
  constructor (
    private apiService: ApiService,
    private injector: Injector,
  ) {}

  private missingKeys: string[] = [];

  handle (key: string, config: TranslocoConfig) {
    const translocoService = this.injector.get(TranslocoService);
    // prevent the same key to land in the TranslocoMissingHandler multiple times
    translocoService.setTranslationKey(key, key, { emitChange: false });
    const lang = translocoService.getActiveLang();
    if (lang === config.defaultLang) {
      return key;
    }
    if (key) this.missingKeys.push(key);
    setTimeout(() => { // to avoid sending multiple requests, we wait 100ms before sending the request
      const missingKeys = [...this.missingKeys];
      this.missingKeys = [];
      if (missingKeys.length) {
        // fetch the translation from the API
        this.apiService.translate(missingKeys, lang).pipe(
          tap((translations: string[]) => {
            if (translations?.length) {
              this.setTranslationsKeys(missingKeys, translations);
            }
          }),
        ).subscribe();
      }
    }, 100);
    return key;
  }

  setTranslationsKeys (keys: string[], translations: string[]) {
    const translocoService = this.injector.get(TranslocoService);
    translations.forEach((translation, index) => {
      // emit the change only on the last translation
      const emitChange = index === translations.length - 1;
      translocoService.setTranslationKey(keys[index], translation, { emitChange });
    });
  }
}
