<ng-container *transloco="let t">
<responsive-layout
  [translatedLinkAccessibility$]="translatedLinkAccessibility$"
  [translatedNavBarConfig$]="translatedNavBarConfig$"
  [translatedNavBarAccessibility$]="translatedNavBarAccessibility$"
>
  <family-header 
    header
    (onLogOut)="logOut()"
    [headerImage]="{
      src: headerProps.headerImage.src,
      alt: t(headerProps.headerImage.alt)
    }"
    [applicationName]="t(headerProps.applicationName)"
    [logOutLabel]="t(headerProps.logOutLabel)"
    [profileLabel]="t(headerProps.profileLabel)"
    [nycIdProfileUrl]="headerProps.profileUrl"
  >
   <ng-template #languageSwitcher>
     <nv-language-switcher
      [mixpanelTrackingFn]="trackLanguageSwitchAction.bind(this)"
      [ariaLabelData]="{ 
        iconAriaLabel: t('Language selector'),
        prependIconAriaLabel: t('Language selector')
      }"
      [selectedLang]="langSwitcherProps.selectedLang"
      [languages]="langSwitcherProps.languages"
      (switchLanguage)="onSwitchLanguage($event)"
     >
    </nv-language-switcher>
   </ng-template>
  </family-header>
  <family-footer footer logoType="symbol"></family-footer>
  <family-footer innerFooter logoType="symbol-invert"></family-footer>
</responsive-layout>
</ng-container>