import { NvButtonModule } from './../../nvps-libraries/design/nv-button/nv-button.module';
import { NgModule } from '@angular/core';
import { TimeOutDialogComponent } from './time-out-dialog.component';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    NvButtonModule,
  ],
  exports: [],
  declarations: [
    TimeOutDialogComponent,
  ],
})

export class TimeOutDialogModule { }
