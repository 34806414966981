<sidebar-list-header
  *ngIf="title"
  [title]="title"
  [subtitle]="subtitle"
  [shouldAnimateSubtitle]="shouldAnimateSubtitle"
></sidebar-list-header>
<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" [ngClass]="toolsMode ? 'tools-mode' : ''">
  <!-- Leaf Nodes -->
  <mat-tree-node
    class="leaf-node"
    [ngClass]="{ 'root-node': node.level === 0, 'child-node': node.level > 0, 'last-accordion-child': node.lastChild === true}"
    *matTreeNodeDef="let node"
  >
    <ng-container *ngIf="node.expandAs === 'dropdown' && node.children; else elseTemplate">
      <!-- Dropdown Node -->
      <sidebar-item
        [id]="node.key + '-sidebar-item'"
        [label]="node.human"
        [isDisabled]="node.disabled"
        [leftDefaultIcon]="node.leftDefaultIcon"
        [leftSelectedIcon]="node.leftSelectedIcon"
        [leftDefaultIconAriaLabel]="node.leftDefaultIconAriaLabel"
        [leftSelectedIconAriaLabel]="node.leftSelectedIconAriaLabel"
        [rightDefaultIcon]="'dropdown-blue'"
        [ngClass]="{ 'last-child': nodeIsLastNode(node), 'disabled' : node.disabled }"
        [matMenuTriggerFor]="dropdownMenu"
      ></sidebar-item>
      <mat-menu #dropdownMenu="matMenu" [overlapTrigger]="true" yPosition="below" panelClass="nv-mat-menu">
        <div *ngFor="let suboption of node.children">
          <nv-dropdown-item (selectItem)="handleSelect($event)" [option]="suboption" ></nv-dropdown-item>
        </div>
      </mat-menu>
    </ng-container>

    <ng-template #elseTemplate>
      <!-- Basic Leaf Node -->
      <sidebar-item
        [id]="node.key + '-sidebar-item'"
        class="basic-leaf-node"
        [label]="node.human"
        [url]="node.url"
        [queryParams]="node.queryParams"
        [isSelected]="nodeIsSelected(node)"
        [isDisabled]="node.disabled"
        [isChild]="node.level > 0"
        [leftDefaultIcon]="node.leftDefaultIcon"
        [leftSelectedIcon]="node.leftSelectedIcon"
        [leftDefaultIconAriaLabel]="node.leftDefaultIconAriaLabel"
        [leftSelectedIconAriaLabel]="node.leftSelectedIconAriaLabel"
        [hasBetaFlag]="node.hasBetaFlag"
        [ngClass]="{ 'last-child': nodeIsLastNode(node) }"
        (sidebarItemSelect)="handleSelect(node.key)"
      ></sidebar-item>
    </ng-template>
  </mat-tree-node>

  <!-- Expandable Accordion Nodes -->
  <mat-tree-node
    class="root-node accordion-node"
    *matTreeNodeDef="let node; when: hasChild"
  >
    <sidebar-item
      matTreeNodeToggle
      [id]="node.key + '-sidebar-item'"
      [leftDefaultIcon]="node.leftDefaultIcon"
      [leftSelectedIcon]="node.leftSelectedIcon"
      [leftDefaultIconAriaLabel]="node.leftDefaultIconAriaLabel"
      [leftSelectedIconAriaLabel]="node.leftSelectedIconAriaLabel"
      [rightDefaultIcon]="treeControl.isExpanded(node) ? caretDownDefaultIcon : caretRightDefaultIcon"
      [rightSelectedIcon]="treeControl.isExpanded(node) ? caretDownSelectedIcon : caretRightSelectedIcon"
      [label]="node.human"
      [isSelected]="nodeIsSelected(node)"
      [isDisabled]="node.disabled"
      [ngClass]="{ 'last-child': nodeIsLastNode(node) }"
      [isChild]="node.level > 0"
      (sidebarItemSelect)="handleSelect(node.key)"
    ></sidebar-item>
  </mat-tree-node>
</mat-tree>
