import { Component, Input, ViewEncapsulation } from '@angular/core';

/* istanbul:ignore-next */
@Component({
  selector: 'sidebar-list-header',
  templateUrl: './sidebar-list-header.component.html',
  styleUrls: ['./sidebar-list-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarListHeaderComponent {
  @Input() title: string;

  private pulse: boolean = false;
  private _subtitle: string;
  @Input() set subtitle (str: string) {
    this._subtitle = str;
    if (this.shouldAnimateSubtitle) {
      this.pulse = true;
      setTimeout(() => {
        this.pulse = false;
      }, 150);
    }
  }

  get subtitle () {
    return this._subtitle;
  }

  @Input() shouldAnimateSubtitle: boolean;
}
