<a class="unformatted-link mat-ripple"
*ngIf="!!url"
[routerLink]="url"
[queryParams]="queryParams"
>
  <button
    matRipple
    class="nv-sidebar-item"
    [ngClass]="{ selected: isSelected, disabled: isDisabled, child: isChild, 'left-icon-layout': !!leftDefaultIcon || !!leftSelectedIcon }"
    (click)="handleClick($event)"
  >
    <nv-icon
      *ngIf="!!leftDefaultIcon && !isSelected"
      class="sidebar-item-icon"
      [name]="leftDefaultIcon"
      nvAriaLabel
      [ariaLabelText]="leftDefaultIconAriaLabel"
      ariaTargetElement="svg"
    ></nv-icon>
    <nv-icon
      *ngIf="!!leftSelectedIcon && isSelected"
      class="sidebar-item-icon"
      [name]="leftSelectedIcon"
      nvAriaLabel
      [ariaLabelText]="leftSelectedIconAriaLabel"
      ariaTargetElement="svg"
    ></nv-icon>
    <span class="sidebar-item-label">{{ label }}</span>
    <nv-icon
      *ngIf="!!rightDefaultIcon && !isSelected"
      class="sidebar-item-dropdown"
      [name]="rightDefaultIcon"
      nvAriaLabel
      [ariaLabelText]="rightDefaultIconAriaLabel"
      ariaTargetElement="svg"
    ></nv-icon>
    <nv-icon
      *ngIf="!!rightSelectedIcon && isSelected"
      class="sidebar-item-dropdown"
      [name]="rightSelectedIcon"
      nvAriaLabel
      [ariaLabelText]="rightSelectedIconAriaLabel"
      ariaTargetElement="svg"
    ></nv-icon>
    <span *ngIf="hasBetaFlag" class="sidebar-item-beta-flag">beta</span>
  </button>
</a>
<button
*ngIf="!url"
matRipple
class="nv-sidebar-item"
[ngClass]="{ selected: isSelected, disabled: isDisabled }"
(click)="handleClick($event)"
>
  <nv-icon
    *ngIf="!!leftDefaultIcon && !isSelected"
    class="sidebar-item-icon"
    [name]="leftDefaultIcon"
    nvAriaLabel
    [ariaLabelText]="leftDefaultIconAriaLabel"
    ariaTargetElement="svg"
  ></nv-icon>
  <nv-icon
    *ngIf="!!leftSelectedIcon && isSelected"
    class="sidebar-item-icon"
    [name]="leftSelectedIcon"
    nvAriaLabel
    [ariaLabelText]="leftSelectedIconAriaLabel"
    ariaTargetElement="svg"
  ></nv-icon>
  <span class="sidebar-item-label">{{ label }}</span>
  <nv-icon
    *ngIf="rightDefaultIcon && !isSelected"
    class="sidebar-item-dropdown"
    [name]="rightDefaultIcon"
    nvAriaLabel
    [ariaLabelText]="rightDefaultIconAriaLabel"
    ariaTargetElement="svg"
  ></nv-icon>
  <nv-icon
    *ngIf="!!rightSelectedIcon && isSelected"
    class="sidebar-item-dropdown"
    [name]="rightSelectedIcon"
    nvAriaLabel
    [ariaLabelText]="rightSelectedIconAriaLabel"
    ariaTargetElement="svg"
  ></nv-icon>
  <span *ngIf="hasBetaFlag" class="sidebar-item-beta-flag">beta</span>
</button>