import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ResponsiveLayoutComponent } from './responsive-layout.component';
import { NavBarModule } from '../responsive-nav-bar/nav-bar.module';
import { NvButtonModule } from '../../nvps-libraries/design/nv-button/nv-button.module';
import { SkipLinkModule } from '../../components/skip-link/skip-link.module';

@NgModule({
  declarations: [ResponsiveLayoutComponent],
  imports: [CommonModule, RouterModule, NavBarModule, NvButtonModule, SkipLinkModule],
  exports: [ResponsiveLayoutComponent],
  providers: [],
  schemas: [],
})
export class ResponsiveLayoutModule {}
