
import { NgModule } from '@angular/core';
import { ApiService } from './api-service/api-service';
import { GraphQLFamilyHelperService } from './graphql-helpers/families/family-queries.service';

@NgModule({
  imports: [],
  providers: [
    ApiService,
    GraphQLFamilyHelperService,
  ],
})
export class ApiModule {}
