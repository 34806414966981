import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FamilyFooterComponent } from './family-footer.component';
import { NvLogoModule } from '../../../../../shared/nvps-libraries/design/nv-logo/nv-logo.module';
import { WindowRef } from '../../../../../shared/services/window-ref/windowRef';
import { TranslateModule } from '../../../app/services/translate/translate.module';

@NgModule({
  declarations: [
    FamilyFooterComponent,
  ],
  imports: [
    CommonModule,
    NvLogoModule,
    TranslateModule,
  ],
  providers: [WindowRef],
  exports: [FamilyFooterComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FamilyFooterModule { }
