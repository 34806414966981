import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  warningMessage: string;
  logOut: () => void;
  onNoClick: () => void;
}

@Component({
  selector: 'time-out-dialog',
  templateUrl: 'time-out-dialog.component.html',
  styleUrls: ['time-out-dialog.component.scss'],
})
export class TimeOutDialogComponent {
  constructor (
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  logOut (): void {
    this.data.logOut();
  }

  onNoClick (): void {
    this.data.onNoClick();
  }
}
