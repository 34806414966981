import { ISidebarItem } from '../../../../shared/layouts/responsive-nav-bar/sidebar-list/sidebar.interface';
import { INavBarConfigContent } from '../../../../shared/layouts/responsive-nav-bar/nav-bar.interface';

export function getFamilyPortalNavbarConfig (translatedContent?: INavBarConfigContent) : { [key:string]: ISidebarItem[] } {
  const content = getContent(translatedContent); // get the translated content if it exists
  return {
    main: [
      {
        human: content.home,
        key: 'Home', // key is hardcoded as it should not be translated
        leftDefaultIcon: 'family-portal-home-default',
        leftSelectedIcon: 'family-portal-home-selected',
        leftDefaultIconAriaLabel: content.home,
        leftSelectedIconAriaLabel: content.home,
        url: '/home',
      },
      {
        human: content.messages,
        key: 'Messages', // key is hardcoded as it should not be translated
        leftDefaultIcon: 'family-portal-message-default',
        leftSelectedIcon: 'family-portal-message-selected',
        leftDefaultIconAriaLabel: content.messages,
        leftSelectedIconAriaLabel: content.messages,
        url: '/messages',
      },
      {
        human: content.myAccount,
        key: 'My Account', // key is hardcoded as it should not be translated
        leftDefaultIcon: 'family-portal-gear-default',
        leftSelectedIcon: 'family-portal-gear-selected',
        leftDefaultIconAriaLabel: content.myAccount,
        leftSelectedIconAriaLabel: content.myAccount,
        url: '/myaccount',
      },
    ],
  };
}

function getContent (translatedContent?: INavBarConfigContent) {
  if (translatedContent) return translatedContent;
  return defaultNavBarContent;
};

export const defaultNavBarContent: INavBarConfigContent = {
  home: 'Home',
  messages: 'Messages',
  myAccount: 'My Account',
};
