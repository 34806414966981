import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { AuthService, AuthorizationParams } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { FamilyPortalConfig } from '../../../services/family-portal-config';

@Injectable()
export class LoginGuard implements CanActivate {
  private AUTHORIZE_OPTIONS_NYCID: AuthorizationParams;
  private publicConfig;

  constructor (
    private auth: AuthService,
    private router: Router,
    private familyPortalConfig: FamilyPortalConfig,
  ) {
    this.publicConfig = this.familyPortalConfig.publicConfig;
    this.AUTHORIZE_OPTIONS_NYCID = {
      connection: this.publicConfig.AUTH0_NYCID_CONNECTION_NAME,
      prompt: 'login',
    };
  }

  canActivate (): Observable<boolean | UrlTree> {
    return this.auth.isAuthenticated$.pipe(
      take(1),
      map(isAuthenticated => {
        if (!isAuthenticated) {
          this.auth.loginWithRedirect({
            authorizationParams: this.AUTHORIZE_OPTIONS_NYCID,
            appState: { target: window.location.pathname }, // return to the url path before redirect
          });
          return false;
        } else {
          return this.router.createUrlTree(['home']);
        }
      }),
    );
  }
};
