import {
  provideTransloco,
  TranslocoModule,
  TRANSLOCO_MISSING_HANDLER,
  TranslocoService,
} from '@jsverse/transloco';
import { provideTranslocoPersistLang } from '@jsverse/transloco-persist-lang';
import { NgModule } from '@angular/core';
import { TranslocoHttpLoader } from './transloco-loader';
import { environment } from 'src/environments/environment';
import { CustomHandler } from './handlers/transloco/transloco-missing-translation-handler';
import { SUPPORTED_LANGUAGES } from '../../../shared/constants/supported-languages';
import { TranslocoServiceDevMode } from './transloco-dev-mode';

const translocoServiceDevMode = [
  {
    provide: TranslocoService,
    useClass: TranslocoServiceDevMode,
  },
];
@NgModule({
  exports: [TranslocoModule],
  providers: [
    ...(environment.PUBLIC_CONFIG.DEV_MODE
      ? translocoServiceDevMode
      : []),
    provideTransloco({
      config: {
        availableLangs: SUPPORTED_LANGUAGES.map((language) => language.key),
        defaultLang: 'en',
        reRenderOnLangChange: true,
        prodMode: environment.production,
        fallbackLang: 'en',
      },
      loader: TranslocoHttpLoader,
    }),
    provideTranslocoPersistLang({
      storage: { useValue: localStorage },
      storageKey: 'lang',
    }),
    {
      provide: TRANSLOCO_MISSING_HANDLER, useClass: CustomHandler,
    },
  ],
})
export class TranslocoRootModule {}
