import { TranslocoService } from '@jsverse/transloco';
import { similarkeyWarning } from './services/translate/translate.service';
import { Injectable } from '@angular/core';

@Injectable()
export class TranslocoServiceDevMode extends TranslocoService {
  translate (...args) : any {
    // Extract the key, params and lang from the arguments for passing them to the parent method
    const key = args[0];
    const params = args[1];
    const lang = args[2];

    // Check if there is a similar key in the translations
    const activeLang = this.getActiveLang();
    similarkeyWarning(key, activeLang);

    // Call the original translate method
    return super.translate(key, params, lang);
  }
}
