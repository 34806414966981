import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatTreeModule } from '@angular/material/tree';
import { NvDropdownItemModule } from '../../../../shared/nvps-libraries/design/nv-dropdown-item/nv-dropdown-item.module';
import { SidebarItemModule } from '../sidebar-item/sidebar-item.module';
import { SidebarListHeaderComponent } from './sidebar-list-header/sidebar-list-header.component';
import { SidebarListComponent } from './sidebar-list.component';
import { A11yModule } from '@angular/cdk/a11y';

@NgModule({
  imports: [CommonModule, SidebarItemModule, MatTreeModule, MatMenuModule, NvDropdownItemModule, A11yModule],
  declarations: [SidebarListComponent, SidebarListHeaderComponent],
  exports: [SidebarListComponent],
})
export class SidebarListModule {}
