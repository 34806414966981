import { ChangeDetectorRef, Component, ElementRef, Input, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { SidebarListComponent } from '../sidebar-list/sidebar-list.component';
/**
 *
 * Extends from NvSidebarListComponent.
 *
 */
@Component({
  selector: 'sidebar-list-responsive',
  templateUrl: '../sidebar-list/sidebar-list.component.html',
  styleUrls: ['../sidebar-list/sidebar-list.scss', 'sidebar-list-responsive.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarListResponsiveComponent extends SidebarListComponent {
  @Input() styleType: 'SIDE' | 'FULL_SCREEN' = 'SIDE';

  constructor (
    public elementRef: ElementRef<HTMLElement>,
    public changeDetectorRef: ChangeDetectorRef,
  ) {
    super(changeDetectorRef);
  }

  ngOnChanges (changes: SimpleChanges) {
    if (this.styleType === 'FULL_SCREEN') {
      this.elementRef.nativeElement.classList.remove('side');
      this.elementRef.nativeElement.classList.add('full-screen');
    }
    if (this.styleType === 'SIDE') {
      this.elementRef.nativeElement.classList.remove('full-screen');
      this.elementRef.nativeElement.classList.add('side');
    }

    if (changes.listData && !changes.listData.firstChange) {
      super.ngOnChanges(changes);
    }
  }
}
