<div class="language-switcher">
  <nv-pill-button-v4
    prependIcon="globe-small-white"
    icon="caret-down-white"
    [ariaLabelData]="ariaLabelData"
    [isDisabled]="isDisabled"
    [matMenuTriggerFor]="isDisabled ? null : menu"
    [text]="selectedLangName"
  ></nv-pill-button-v4>
  <mat-menu #menu="matMenu">
    <ng-container *ngFor="let option of languages;">
        <button
          mat-menu-item
          (click)="onSwitchLanguage(option)"
          [id]="option.key"
          [disabled]="option.disabled"
        >
          <div class="label-wrapper">
            <span class="dropdown-item-label">
              <span class="{{ option.customClass }}">{{ option.human }}</span>  
            </span>
          </div>
        </button>
    </ng-container>
  </mat-menu>
</div>