import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { ILink } from './skip-link.interface';

@Component({
  selector: 'skip-link',
  templateUrl: './skip-link.component.html',
  styleUrls: ['./skip-link.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SkipLinkComponent {
  @Input() links?: Array<ILink> = [];
  @Output() skipLinkClick: EventEmitter<string> = new EventEmitter<any>();

  public onSkipLinkClick (target: string): void {
    this.skipLinkClick.emit(target);
  }
}
