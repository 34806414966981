
import { Injectable } from '@angular/core';
import { ApiService } from '../api/api-service/api-service';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { ICurrentUser } from 'src/app/interfaces/current-user.interface';

@Injectable()
export class CurrentUserService {
  private currentUser$: BehaviorSubject<ICurrentUser> = new BehaviorSubject(null);
  private familyChildrenDbns$: BehaviorSubject<string[]> = new BehaviorSubject(null);

  constructor (
    private apiService: ApiService,
  ) {}

  getCurrentUser$ () : Observable<ICurrentUser> {
    if (this.currentUser$.value) {
      return this.currentUser$.asObservable();
    }

    return this.apiService.getCurrentUser().pipe(
      map(({ data: { currentUser } }) => {
        // set the current user in the behavior subject so that it can be accessed without making another api call
        this.currentUser$.next(currentUser);
        return currentUser;
      }),
      catchError((error) => throwError(error)),
    );
  }

  getCurrentUserChildrenDbns$ (familyId: string): Observable<string[]> {
    if (this.familyChildrenDbns$.value) {
      return this.familyChildrenDbns$.asObservable();
    }

    return this.apiService.getChildrenDbns(familyId).pipe(
      map(({ data: { studentDbns: { studentDbns } } }) => {
        // set the studentDbns in the behavior subject so that it can be accessed without making another api call
        this.familyChildrenDbns$.next(studentDbns);
        return studentDbns;
      }),
      shareReplay(1),
      catchError((error) => throwError(error)),
    );
  }
}
