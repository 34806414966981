import { TFamilyEventName } from './../../../../../shared/typings/interfaces/family-mixpanel.interface';
import { FamilyPortalConfig } from '../family-portal-config';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import * as Mixpanel from 'mixpanel-browser';
import { switchMap, take, tap } from 'rxjs/operators';
import { CurrentUserService } from '../current-user/current-user.service';
import { ICurrentUser } from 'src/app/interfaces/current-user.interface';
import { Observable, forkJoin, of } from 'rxjs';

const ignoreDefaultPropertyList = [
  '$current_url',
  '$initial_referrer',
  '$initial_referring_domain',
  '$referring_domain',
  '$referrer',
  '$os',
];

type userProps = 'Last Login' | 'DBNs' | 'Family Id' | '$email';
// eslint-disable-next-line no-unused-vars
type userPropsMap = { [key in userProps]?: string | number };

export const MixpanelClient = new InjectionToken<Mixpanel>('mixpanelClient', { factory: () => Mixpanel });
export type TFamilyMixpanelEvent<TFamilyMetaData> = { event: TFamilyEventName; metaData: TFamilyMetaData };

@Injectable()
export class FamilyPortalMixpanelService {
  private inDevMode: boolean;
  private userPropsMap: Map<userProps, string | number> = new Map();

  constructor (
    private currentUserService: CurrentUserService,
    private familyPortalConfig: FamilyPortalConfig,
      @Inject(MixpanelClient) private mixpanelClient: Mixpanel,
  ) {
    this.inDevMode = this.familyPortalConfig.publicConfig.DEV_MODE;
    if (!this.inDevMode) {
      this.mixpanelClient.init(this.familyPortalConfig.publicConfig.FAMILY_MIXPANEL_PROJECT_TOKEN, {
        property_blacklist: ignoreDefaultPropertyList,
        ip: false,
      });
    }
  }

  private setUserPropsMap (userProps: userPropsMap): void {
    Object.entries(userProps).forEach(([key, val]: [userProps, string | number]) => {
      this.userPropsMap.set(key, val);
    });
  }

  private shouldUpdateUser (newProps: userPropsMap): boolean {
    if (this.userPropsMap.size === 0) return true;
    return !Object.entries(newProps).every(([key, val]: [userProps, string | number]) => {
      return this.userPropsMap.get(key) === val;
    });
  }

  private getCurrentUser$ (): Observable<ICurrentUser> {
    return this.currentUserService.getCurrentUser$().pipe(
      take(1),
    );
  }

  private getStudentDbns$ (familyId: string): Observable<string[]> {
    return this.currentUserService.getCurrentUserChildrenDbns$(familyId).pipe(
      take(1),
    );
  }

  public trackEvents <T> (events: TFamilyMixpanelEvent<T>[]): void {
    if (!this.inDevMode) {
      this.getCurrentUser$().pipe(
        switchMap(user => {
          return forkJoin({
            user: of(user),
            studentDbns: this.getStudentDbns$(user.familyId),
          }).pipe(
            tap(({ user, studentDbns }) => {
              const { guardianId, email, familyId } = user;
              const newProps = {
                'Family Id': familyId,
                DBNs: studentDbns.join(', '),
                $email: email, // $email = reserved Mixpanel email field
              };
              if (guardianId && this.shouldUpdateUser(newProps)) {
                // Update mixpanel user
                this.updateUser(guardianId, newProps);
              }

              // Events
              events.forEach(
                ({ event, metaData }) => {
                  this.mixpanelClient.track(event, {
                    analyticLevel: 'Client',
                    distinct_id: guardianId,
                    ...metaData,
                  });
                },
              );
            }),
            take(1),
          );
        }),
        take(1),
      ).subscribe();
    }
  }

  public updateUser (guardianId: string, newProps: userPropsMap): void {
    if (!this.inDevMode) {
      this.setUserPropsMap(newProps);
      this.mixpanelClient.identify(guardianId);
      this.mixpanelClient.people.set(newProps);
    }
  }
}
