import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvButtonModule } from '../../nvps-libraries/design/nv-button/nv-button.module';
import { SkipLinkComponent } from './skip-link.component';

@NgModule({
  declarations: [SkipLinkComponent],
  imports: [CommonModule, NvButtonModule],
  exports: [SkipLinkComponent],
  providers: [],
  schemas: [],
})
export class SkipLinkModule {}
